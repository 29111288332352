<template>
  <div class="search">
    <navbar :showBack="false" />
    <div class="search-wrap">
      <div v-if="!showSelect" class="normal-search">
        <img src="@/assets/logo-full.png" alt="" class="logo" />
        <div class="search-input">
          <input
            type="text"
            v-model="value"
            placeholder="请输入谱名、堂号、始祖、简介等"
          />
          <van-button class="search-btn" @click="handleSearch(value)">
            搜索
          </van-button>
        </div>
        <van-button class="exact-search-btn" @click="showSelect = true">
          精确搜索
        </van-button>
        <van-button class="exact-search-btn" @click="handleSearch('')">
          立即进入族谱阁
        </van-button>
      </div>
      <div v-else class="exact-search">
        <input
          type="text"
          v-for="item in searchKeys"
          :key="item.key"
          v-model="query[item.key]"
          :placeholder="`请输入${item.label}`"
        />
        <van-button class="search-btn" @click="handleSearch()">
          精确搜索
        </van-button>
      </div>
      <!-- <form action="/">
        <van-search
          class="search-input"
          v-model="value"
          shape="round"
          show-action
          placeholder="请输入搜索关键词"
          @search="handleSearch"
          @cancel="handleCancel"
        >
          <template #label>
            <div class="show-down" @click="showSelect = !showSelect">
              <span>{{ selectSearchKey.label }}</span>
              <van-icon :name="showSelect ? 'arrow-up' : 'arrow-down'" />
            </div>
          </template>
          <template #left-icon>
            <van-icon name="search" @click="handleSearch()" />
          </template>
        </van-search>
        <div v-if="showSelect" class="search-keys">
          <div
            v-for="item in searchKeys"
            :key="item.key"
            class="search-key-item"
            :class="{ active: item.key === selectSearchKey.key }"
            @click="selectSearchKey = item"
          >
            {{ item.label }}
          </div>
          <div class="search-key-item" style="opacity: 0"></div>
        </div>
      </form> -->
    </div>
    <div class="history">
      <div v-if="history.size > 0" class="title">
        <span>历史搜索</span>
        <van-icon size="14px" name="delete-o" />
      </div>
      <div
        v-for="(item, index) in history"
        :key="item"
        class="history-item"
        @click="handleSearch(item)"
      >
        <span :class="{ matched: matchedIndex === index }">{{ item }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Search, Icon, Button } from "vant";
import Navbar from "@/components/h5/Navbar.vue";

const searchKeys = [
  {
    label: "编号",
    key: "sn"
  },
  {
    label: "标题",
    key: "title"
  },
  {
    label: "谱籍地",
    key: "pujidi"
  },
  {
    label: "支系",
    key: "timing"
  },
  {
    label: "修撰者",
    key: "xiuzhuanzhe"
  },
  {
    label: "版本年",
    key: "banbennian"
  },
  {
    label: "堂号",
    key: "tanghao"
  },
  {
    label: "字辈",
    key: "zibei"
  },
  {
    label: "发源地",
    key: "fayuandi"
  },
  {
    label: "简介",
    key: "description"
  }
];

export default {
  name: "Search",
  components: {
    // "van-search": Search,
    "van-icon": Icon,
    [Button.name]: Button,
    Navbar
  },
  data() {
    return {
      value: "",
      history: new Set(),
      searchKeys,
      showSelect: false,
      query: {
        sn: "",
        title: "",
        pujidi: "",
        timing: "",
        xiuzhuanzhe: "",
        banbennian: "",
        tanghao: "",
        zibei: "",
        fayuandi: "",
        description: ""
      }
      // matchedIndex: -1
    };
  },
  computed: {
    matchedIndex() {
      return this.value
        ? Array.from(this.history).findIndex(item => item.includes(this.value))
        : -1;
    }
  },
  activated() {
    this.value = "";
  },
  methods: {
    handleCancel() {
      console.log(history);
      if (history.length <= 2) {
        this.$router.replace("/h5/home");
      } else {
        this.$router.back();
      }
    },

    handleSearch(item) {
      item = item || this.value;
      // if (this.history.indexOf(item)) {
      //   this.history.splice(this.history.indexOf(item), 1);
      // }
      const query = this.showSelect ? this.query : { search_text: item };
      this.$router.push({
        path: "/h5/search-result",
        query
      });
    }
  }
};
</script>

<style lang="scss">
.van-search__action {
  font-size: 44px;
  color: #0ab0fb;
}
</style>

<style lang="scss" scoped>
.search {
  height: calc(100vh - 158px);
  background-color: #fff;
}
.search-wrap {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .normal-search {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    top: -200px;

    .logo {
      height: 150px;
      object-fit: contain;
    }

    .search-input {
      display: flex;
      align-items: center;
      margin-top: 100px;

      input {
        width: 800px;
        height: 120px;
        background: #f7f7f7;
        border: 1px solid #f0f0f0;
        border-radius: 10px;
        font-size: 36px;
        color: #666;
        box-sizing: border-box;
        margin-right: 30px;
        text-align: center;
      }

      .search-btn {
        width: 210px;
        height: 120px;
        background: #ca0a15;
        border-radius: 10px;
        color: #fff;
      }
    }

    .exact-search-btn {
      width: 1045px;
      height: 120px;
      border: 1px solid #ca0a15;
      border-radius: 10px;
      color: #ca0a15;
      font-size: 36px;
      margin-top: 50px;
    }
    // height: 100%;
  }

  .exact-search {
    display: flex;
    flex-flow: column nowrap;
    margin-top: -400px;

    input {
      width: 1045px;
      height: 120px;
      padding: 40px;
      box-sizing: border-box;
      background: #f7f7f7;
      border: 1px solid #f0f0f0;
      border-radius: 10px;
      font-size: 36px;
      margin-bottom: 30px;
    }

    .search-btn {
      width: 1045px;
      height: 120px;
      background: #ca0a15;
      border-radius: 10px;
      color: #fff;
    }
  }
}
</style>
